<template>
  <div>
    <div class="card">
      <div class="card-body user-select-none">
        <div>
          <span class="h5">{{ dayText }}</span>
          <span v-if="isToday" class="text-muted"> &mdash; Today</span>
        </div>

        <div class="text-center my-4">
          <div :class="modeStyle" class="badge rounded-pill mb-4">{{ modeText }}</div>
          <div class="fs-1 lh-1">{{ data.total.value | numberReadable }}</div>
          <div class="mt-1">{{ data.total.unit }}</div>
        </div>

        <div class="t1 fw-bold mt-2">
          Carbohydrate
          ({{ data.c.value * 400 / data.total.value | round | numberReadable }}%)
        </div>
        <div class="d-flex align-items-center fs-4 fw-light">
          <div>{{ data.c.value }}g</div>
          <div class="flex-grow-1 spacer"></div>
          <div>{{ data.c.value * 4 | numberReadable }}cal</div>
        </div>

        <div class="t2 fw-bold mt-2">
          Protein
          ({{ data.p.value * 400 / data.total.value | round | numberReadable }}%)
        </div>
        <div class="d-flex align-items-center fs-4 fw-light">
          <div>{{ data.p.value }}g</div>
          <div class="flex-grow-1 spacer"></div>
          <div>{{ data.p.value * 4 | numberReadable }}cal</div>
        </div>

        <div class="t3 fw-bold mt-2">
          Fat
          ({{ data.f.value * 900 / data.total.value | round | numberReadable }}%)
        </div>
        <div class="d-flex align-items-center fs-4 fw-light">
          <div>{{ data.f.value }}g</div>
          <div class="flex-grow-1 spacer"></div>
          <div>{{ data.f.value * 9 | numberReadable }}cal</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import dayjs from 'dayjs';

export default {
  name: 'NutritionDayCard',
  props: {
    dayIndex: {
      type: Number,
      required: true,
    },
    mode: {
      type: Number,
      required: true,
    },
    data: {
      type: Object,
      required: true,
    },
  },
  computed: {
    dayText() {
      const days = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];
      return days[this.dayIndex];
    },
    modeText() {
      const { mode } = this;
      if (mode < 0) return 'Deficit';
      if (mode > 0) return 'Surplus';
      return 'Maintenance';
    },
    modeStyle() {
      const { mode } = this;
      if (mode < 0) return 'bg-danger';
      if (mode > 0) return 'bg-primary';
      return 'bg-dark';
    },
    isToday() {
      let todayIndex = dayjs().format('d');
      todayIndex = todayIndex === 0 ? 6 : todayIndex - 1;
      return todayIndex === this.dayIndex;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/scss/vars.scss";

.t1 {
  color: $primaryAccent;
}

.t2 {
  color: $secondaryAccent;
}

.t3 {
  color: $tertiaryAccent;
}

.spacer {
  position: relative;

  &::before {
    content: "";
    position: absolute;
    left: 1rem;
    right: 1rem;
    top: 0;
    border-top: 1px dashed $borderColor;
  }
}
</style>
